@import '~rsuite/dist/rsuite.min.css';

.apexcharts-menu-item,
.apexcharts-theme-light .apexcharts-tooltip-title, 
.apexcharts-theme-light .apexcharts-tooltip-text
{
    color: #292d33 !important;
}

a.rs-navbar-brand {
    font-size: 16px;
}

nav.rs-sidenav {
    height: 100%;
}

div.rs-sidenav-toggle {
    margin-top: 0;
}

html, body, #app, #root, #work_area, #app_loading {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

#app_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #292d33;
    color: #fff;
    font-size: 24px;
}

#work_area {
    display: flex;
    flex-grow: 1;
}

#app main {
    width: 100%;
    height: calc(100vh - 104px);
    overflow-y: auto;
}

body {
    margin: 0;
}

h2.header {
    padding: 25px 0;
    display: inline-block;
    text-align: center;
    width: 100%;
}

h2.header i {
    line-height: normal;
    display: inline-block;
    vertical-align: middle;
}

div.middle-flex {
    width: 60%;
    margin: 0 auto;
}

.treeArea {
    padding: 25px 15px;
    overflow: scroll;
    text-align: center;
}

.treeArea ul {
    --line-color: #fff;
}

.treeRoot, .treeChildHigh, .treeChildMedium, .treeChildLow {
    padding: 15px;
    border-radius: 8px;
    display: inline-block;
    width: 200px;
    height: 126px;
    background-color: var(--rs-gray-700);
    font-size: 14px;
    overflow: hidden;
    position: relative;
}

.treeRoot::before {
    display:block;
    height:48px;
    width:100%;
    content: "";
    background:url('../public/assets/sql.png') no-repeat 0 0;
    background-size: 48px 48px;
    background-position: center;
    margin: 10px 0;
}

.treeChildHigh:before, .treeChildMedium:before, .treeChildLow::before {
    display:block;
    height:48px;
    width:100%;
    content: "";
    background:url('../public/assets/1С.png') no-repeat 0 0;
    background-size: 48px 48px;
    background-position: center;
    margin: 10px 0;
}

.rs-btn-icon.btn-tree {
    position: absolute;
    top: 7px;
    right: 7px;
}

.status-list h3{
    font-size: 20px;
    padding: 15px 0;
}

.status-list {
    padding: 15px 0;
}

.status-list .rs-list-item {
    font-size: 18px;
}

.status-list .rs-flex-box-grid-top {
    align-items: center;
}

.status-list .rs-flex-box-grid .rs-flex-box-grid-item {
    display: inherit;
}

.modal-fixed-custom{
    min-height: 350px;
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

div.middle-flex-large {
    width: 90%;
    margin: 0 auto;
}

.profileInfo {
    display: flex;
    font-size: initial;
    padding: 5px 0;
}

.profileInfo b {
    padding-right: 7px;
}

div.lowPadding {
    padding: 5px 0;
}

span.deniedSpan {
    display: flex;
    padding: inherit;
    justify-content: center;
}

.rs-table-cell {
    height: 64px;
    min-height: 64px;
}

.rs-table-cell-content {
    padding: 0;
}

span.rs-tree-node-label,
span.rs-tree-node-label-content {
    width: 100% !important;
}

div.treeNone {
    padding: 15px;
    border-radius: 8px;
    display: inline-block;
    width: 200px;
    height: 126px;
    background-color: var(--rs-gray-700);
    font-size: 14px;
    overflow: hidden;
    position: relative;
    margin: 10px;
    text-align: center;
}

div.treeNone:before {
    display:block;
    height:48px;
    width:100%;
    content: "";
    background:url('../public/assets/1С.png') no-repeat 0 0;
    background-size: 48px 48px;
    background-position: center;
    margin: 10px 0;
}

div.treeNone button {
    position: absolute;
    right: 7px;
    top: 7px;
}

.rs-modal-footer{
    padding-top: 14px;
}

.rs-modal-body{
    padding-bottom: 0;
}

.rs-table-row.rs-table-row-header{
    min-width: auto !important;
}

.flex_modal_form{
    display: flex
}

.flex_modal_form .rs-form-control{
    display: flex;
    flex-grow: 1;
}

.flex_modal_form .rs-form-control input{
    display: flex;
    flex-grow: 1;
    max-width: calc(100% - 15px);
}

.react-flow__node-infoNode {
    font-size: 8px;
    border-radius: 5px;
    text-align: left;
    width: 175px;
    color: #292d33 !important;
    background-color: #fff !important;
    height: 105px;
}

.react-flow__node-input,
.react-flow__node-output{
    font-size: 8px;
    border-radius: 5px;
    text-align: center;
    width: 175px;
    color: #292d33 !important;
    background-color: #fff !important;
}

.react-flow__node-output{
    height: 105px;
}

.Spreadsheet__table{
    width: 100% !important;
}